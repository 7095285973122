import Icon from '@/components/Amp/Icon';
import classNames from '@/utils/className';
import { Fragment } from 'react';

interface offerBannerProps {
  isOnHome?: boolean;
  phrase?: string;
  link?: string;
}

export default function OfferBanner({
  isOnHome = false,
  phrase = 'Siga o Canal do CT no WhatsApp',
  link = 'https://canalte.ch/canaltech-no-whatsapp-site',
}: offerBannerProps) {
  const textBanner = phrase;

  const Container = ({ children }) => {
    if (isOnHome)
      return (
        <div className="relative flex w-full flex-col flex-wrap content-center justify-center bg-mglGray50 pt-6">
          <div className="w-full px-6">{children}</div>
        </div>
      );
    return <Fragment>{children}</Fragment>;
  };

  return (
    <Container>
      <div className={classNames('flex w-full items-center justify-center gap-2 rounded bg-[#25D366] p-4', !isOnHome && 'mb-8')}>
        <span className=" text-sm leading-tight text-mglGray900 tablet:text-base">{textBanner}</span>
        <a
          href={link}
          target="_blank"
          className="canal-ct-wpp flex appearance-none items-center gap-1 rounded-3xl border-0 bg-mglGray900 px-4 py-2 text-xs text-mglGray50 outline-none transition-opacity hover:opacity-80 focus:opacity-80"
          title={textBanner}>
          <Icon icon="mdi:whatsapp" /> Entrar
        </a>
      </div>
    </Container>
  );
}
